.mainCon {
    width: 70%;
    /* border: 2px solid yellow; */
    padding-top: 6rem;
    transition: 1s;
}

/*Title Container*/
.titleCon {
    /* border: 2px solid red; */
    display: flex;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.titleCon h1 {
    font-weight: lighter;
    font-size: 2.5rem;
    color: var(--main-yellow);
}

.circle{
    background-color: var(--main-yellow);
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
}

/*Cards Container*/
.cardsCon{
    /* border: 2px solid green; */
    margin-top: 4rem    ;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
}
.card {
    border-radius: 15px;
    border: 5px solid var(--main-yellow);
    padding: 0;
    height: 27rem;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.card img {
    width: 120%;
    height: 100%;
    object-fit: cover;

}

.infoCard {
    position: absolute;
    top: 350px;
    left: -1px;
    width: 101%;
    height: 26.1rem;
    padding-top: 1rem;
    background-color: var(--main-yellow);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    transition: 0.5s;
}
.card:hover .infoCard {
    top: 0;
}
.infoCard h1 {
    color: black;
}
.infoText {
    width: 85%;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: black;
    text-align: justify;
}

.infoCard button {
    background-color: black;
    border: none;
    color: white;
    font-size: 1.3rem;
    width: 8rem;
    height: 3rem;
}

.infoCard button:hover {
    background-color: white;
    color: black;
}

@media screen and (max-width: 1320px) {
    .cardsCon{
        margin: 4rem auto;
        width: 80%;
        grid-template-columns: 1fr 1fr;
    }
    
}
@media screen and (max-width: 1200px) {
    .cardsCon{
        width: 90%;
    }
    
}

@media screen and (max-width: 1080px) {
    .cardsCon{
        width: 100%;
    }
    
}

@media screen and (max-width: 900px) {
    .mainCon{
        width: 100%;
    }
    .cardsCon{
        width: 80%;
    }
    
}

@media screen and (max-width: 800px) {
    .mainCon{
        margin: 0;
    }
    .cardsCon{
        width: 20rem;
        grid-template-columns: 1fr;
    }
    
}