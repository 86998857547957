.mainCon {
   /*  border: 2px solid yellow; */
    width: 70%;
    min-height: 35rem;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 1s;
}

.titleCon {
    /* border: 2px solid red; */
    display: flex;
    width: 100%;
    margin-top: 1rem;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
}

.titleCon h1 {
    margin-right: 4rem;
    font-weight: lighter;
    font-size: 2.5rem;
    color: var(--main-yellow);
}

.circle{
    background-color: var(--main-yellow);
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
}

/*Card Container*/
.cardCon {
    /* border: 2px solid red; */
    width: 70%;
    margin-top: 4rem;
}

.backCard {
    border: 5px solid var(--main-yellow);
    margin-left: 1.5rem;
    width: 80%;
    height: 20rem;
    position: relative;
    border-radius: 15px;
}
.backCard h1 {
    font-weight: 200;
    color: var(--main-yellow);
    margin: 0.5rem 1rem;
}
.card {
    position: absolute;
    top: 3.5rem;
    left: 6rem;
    width: 100%;
    height: 20rem;
    border-radius: 15px;
    background-color: var(--main-yellow);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .5s;
}
.backCard:hover .card{
    top: 2rem;
    left: 4.5rem;
}
.backCard:hover {
    border: 5px solid var(--main-blue);
}
.cardText{

    width: 90%;
    margin-top: 2rem ;
}
.cardText p {
    font-size: 1.7rem;
    letter-spacing: 1px;
    color: black;
    font-weight: 400;
}
.cardRedes{
    width: 60%;
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-around;
}
.cardRedes img {
    width: 50px;
}
.sayHi{
    
    width: 90%;
    margin-top: 2rem ;
    display: grid;
    justify-items: center;
}
.sayHi button {
    border: none;
    background-color: #01090e;
    width: 8rem;
    height: 3rem;
    font-size: 1.5rem;
    color: rgb(222, 224, 210);
}

.sayHi button:hover {
    background-color: var(--main-blue);
    color: #01090e;
}

@media screen and (max-width: 1520px) {
    .backCard{
        height: 22rem;
    }
    .card{
        height: 22rem;
    }
    
}

@media screen and (max-width: 1200px) {
    .backCard{
        height: 20em;
    }
    .card{
        height: 20rem;
    }
    .mainCon{
        width: 100%;
    }
    
}
@media screen and (max-width: 1090px) {
    .backCard{
        height: 22em;
    }
    .card{
        height: 22rem;
    }
    .mainCon{
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 840px) {
    .backCard{
        height: 24rem;
    }
    .card{
        height: 24rem;
    }
    .cardText p {
        font-size: 1.5rem;
        
    }
    
}

@media screen and (max-width: 700px) {
    .cardText p {
        font-size: 1.5rem;
        
    }
    .cardRedes{
        width: 80%;
    }
    .backCard{
        border: none;
    }
    .card{
        position: absolute;
        left: -15%;
        width: 130%;
    }
    .backCard:hover .card{
        top: 3.5rem;
        left: -15%;
    }
    .backCard:hover {
        border: none;
    }
}

@media screen and (max-width: 515px) {
    .cardText p {
        font-size: 1.5rem;
        
    }
    .cardRedes{
        width: 80%;
    }
}
@media screen and (max-width: 400px) {
    .cardText p {
        font-size: 1.2rem;
        
    }
    .cardRedes{
        width: 90%;
    }

}
