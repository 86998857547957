.mainCon {
    width: 70%;
    display: flex;
    transition: 0.5s;
    transition: 1s;
    padding-top: 3rem;
}



/*Information container*/
.infoAbout{
    /* border: 2px solid yellow; */
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    gap: 0rem;
}


.infoTitleCon{
    width: 75%;
    height: 10rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.circle{
    background-color: var(--main-yellow);
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
}
.infoTitleCon h1{
    font-weight: lighter;
    font-size: 2.5rem;
    color: var(--main-yellow);
}

.infoTextCon{
    border: 4px solid var(--main-yellow);
    border-radius: 15px;
    width: 65%;
    /* min-height: 60vh; */
    padding: 1rem;
}
.infoTextCon p {
    font-size: 1.3rem;
    color: rgb(173, 173, 173);
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: justify;
}
.infoTextCon span {
    color: var(--main-blue);
}

/*Photo and button container*/
.photoBtnCon{
    /* border: 2px solid red; */
    height: 41rem;
    width: 40%;
    display: flex;
    flex-direction: column;
   
}

.PBsecondary{
    /* border: 2px solid yellow; */
    margin-top: 5rem;
    width: 80%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.photoCon{
    margin: 2rem 0;
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items:flex-start;
    overflow: hidden;
}

.photoCon img{
    width: 120%;
}

.tech {
    height: 27%;
    display: flex;
    flex-direction: column;
    gap: 1.7rem;
}
.tech p {
    font-weight: 200;
    font-size: 1.5rem;
    letter-spacing: 1px;
    color: var(--main-yellow);
}

.btnCon{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.photoBtnCon button {
    height: 3.2rem;
    width: 10rem;
    background-color: black;
    border: 4px solid var(--main-yellow);
    border-radius: 10px;
    color: var(--main-yellow);
    font-size: 2rem;
    font-weight: 400;
    padding-bottom: 2rem;
    transition: 0.5s;
}
.PBsecondary a {
    width: 10rem;
}
.photoBtnCon button:hover {
    background-color: var(--main-yellow);
    color: black;
}

.techs {
    /* border: 2px solid blue; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}
.techs span {
    color: var(--main-blue);
}

/*Media querys -----------------------------------------------*/

@media screen and (max-width: 1600px) {
    .infoTextCon p {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1400px) {
    .infoTextCon p {
        font-size: 1.1rem;
    }
    .techs span {
        font-size: 0.8rem;
    }
    
}

@media screen and (max-width: 1200px) {
    .mainCon {
        width: 80%;
    }
    
    .btnCon{
        margin-top: 1.5rem;
    }
    
}


@media screen and (max-width: 900px) {
    .mainCon {
        width: 100%;
        
    }
    
}

@media screen and (max-width: 750px) {
    .mainCon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        align-items: center;
        width: 100%;
    }
    .infoAbout{
        width: 100%;
    }
    .infoTextCon{
        width: 80%;
    }
    .photoBtnCon{
        
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 4rem;
    }
    .btnCon{
        margin-top: 4rem;
    }
    .techs{
        margin-top: 1rem;
    }
    .tech{
        margin-top: 2rem;
    }
}

@media screen and (max-width: 500px) {
    .infoAbout{
        width: 100%;
    }
    .photoBtnCon{
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50rem;
    }
    .infoTextCon p {
        font-size: 1rem;
    }
    .PBsecondary{

        width: 100%;
    }
    .tech{
        width: 100%;
        height: 28%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    .techs span {
        font-size: 0.7rem;
    }
    .photoCon{
        margin: 2rem 0 2rem 0;
    }

}