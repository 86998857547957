.mainCon {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--main-yellow);
    gap: 2rem;
}
.mainCon h1 {
    font-size: 10rem;
}
.mainCon p {
    font-size: 2rem;
    letter-spacing: 2px;
}
.mainCon button {
    height: 3rem;
    width: 12rem;
    font-size: 1.5rem;
    border: 2px solid var(--main-yellow);
    background-color: transparent;
    color: var(--main-yellow);
}
.mainCon button:hover {
    background-color: var(--main-yellow);
    color: black;
}