@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body {
    background-color: #01090e;
    color: white;
    font-family: 'Oswald', sans-serif;

    
}
h1, h2, h3, p {
    margin: 0;
}

:root {
    --main-yellow: #e7c52e;
    --main-blue: #13eadf;
}